<template>
  <Container>
    <Header>
      Suppliers
      <template #right>
        <BadgeBtn @click="openSupplier()">
          + Supplier
        </BadgeBtn>
      </template>
    </Header>
    <SupplierTable />
  </Container>
</template>

<script>
import SupplierTable from "../components/tabels/SupplierTable.vue"

import comp from "../components/base"
import { mapActions } from "vuex"
export default {
  name: "Suppliers",
  components: {
    ...comp,
    SupplierTable,
  },
  methods: {
    ...mapActions(["openSupplier"]),
  },
}
</script>
